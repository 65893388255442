import { motion } from "framer-motion";
import { Loader2 } from "lucide-react";
import React from "react";

// show the loader after a second has passed and still loading
const Loader = () => {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0, delay: 1 }}
			className="flex-center size-full"
		>
			<Loader2 className="size-8 animate-spin text-primary" />
		</motion.div>
	);
};

export default Loader;
