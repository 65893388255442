"use client";

import { cn } from "@/lib/utils";
import { urlForImage } from "@/sanity/lib/image";
import { ImageType } from "@/sanity/query/image";
import Image from "next/image";
import { HTMLAttributes, useEffect } from "react";
import { useInView } from "react-intersection-observer";

type LazyImgProps = {
	image: ImageType;
	className?: string;
	tabIndex?: number;
	centerSlug?: string;
} & HTMLAttributes<HTMLImageElement>;

export default function LazyImage({
	image,
	className,
	tabIndex,
	centerSlug,
}: LazyImgProps) {
	const { ref, inView } = useInView({
		triggerOnce: true,
		threshold: 0.5,
	});

	useEffect(() => {
		if (image?.project?.slug === centerSlug) {
			document.getElementById(image.id)?.scrollIntoView({
				inline: "center",
				block: "center",
				behavior: "instant",
			});
		}
	}, [image.id, centerSlug, image?.project?.slug]);

	return (
		<div
			style={{
				backgroundImage: `url(${image.metadata.lqip})`,
				backgroundSize: "cover",
			}}
			tabIndex={tabIndex}
			className={cn("relative", className)}
		>
			<Image
				id={image.id}
				ref={ref}
				sizes="500px"
				src={urlForImage(image.url).url()}
				fill
				alt={image.alt ?? image.url}
				className="object-cover"
				loading="lazy"
				style={{
					opacity: inView ? 1 : 0,
					transition: "opacity 0.3s ease-in-out",
				}}
			/>
		</div>
	);
}
