import { cn } from "@/lib/utils";
import React, { HTMLAttributes } from "react";

interface BlurContextType {
	hovered: number | null;
	setHovered: React.Dispatch<React.SetStateAction<number | null>>;
}

interface BlurListItemContextType {
	index: number;
}

interface Props {
	children: React.ReactNode;
	className?: string;
}

// Contexts to be used in the BlurList----------------------------
const BlurContext = React.createContext<BlurContextType | undefined>(undefined);

const BlurListItemContext = React.createContext<
	BlurListItemContextType | undefined
>(undefined);

// Hooks to be used in the BlurList----------------------------
const useBlur = () => {
	const context = React.useContext(BlurContext);
	if (context === undefined) {
		throw new Error("useBlur must be used within a BlurProvider");
	}
	return context;
};

const useBlurListItem = () => {
	const context = React.useContext(BlurListItemContext);
	if (context === undefined) {
		throw new Error(
			"useBlurListItem must be used within a BlurListItemProvider",
		);
	}
	return context;
};

// Providers to be used in the BlurList----------------------------
const BlurProvider = ({ children }: Props) => {
	const [hovered, setHovered] = React.useState<number | null>(null);

	return (
		<BlurContext.Provider value={{ hovered, setHovered }}>
			{children}
		</BlurContext.Provider>
	);
};

const BlurListItemProvider = ({
	children,
	index,
}: Props & { index: number }) => {
	return (
		<BlurListItemContext.Provider value={{ index }}>
			{children}
		</BlurListItemContext.Provider>
	);
};

// Components to be used in the BlurList-------------------------

const BlurList = ({
	children,
	className,
	...props
}: HTMLAttributes<HTMLSlotElement>) => {
	return (
		<BlurProvider>
			<slot className={cn(className)} {...props}>
				{children}
			</slot>
		</BlurProvider>
	);
};

const BlurListItem = React.forwardRef<
	HTMLDivElement,
	HTMLAttributes<HTMLDivElement> & { index: number }
>(({ children, className, index, ...props }, ref) => {
	const { setHovered } = useBlur();

	return (
		<BlurListItemProvider index={index}>
			<div
				onMouseEnter={() => setHovered(index)}
				onMouseLeave={() => setHovered(null)}
				className={cn(className)}
				{...props}
			>
				{children}
			</div>
		</BlurListItemProvider>
	);
});
const BlurListItemOverlay = ({
	className,
	children,
	...props
}: HTMLAttributes<HTMLDivElement>) => {
	const { hovered } = useBlur();
	const { index } = useBlurListItem();

	return (
		<div className="relative">
			<div
				className={cn(
					"absolute top-0 bottom-0 left-0 right-0 z-40 size-full transition-opacity duration-500",
					hovered === null && "hide-blur",
					hovered !== null &&
						(hovered === index
							? "hide-blur -z-10"
							: "show-blur  shadow-2xl shadow-inherit"),
					className,
				)}
				{...props}
			/>
			{children}
		</div>
	);
};
const BlurListItemInfo = ({ children, className }: Props) => {
	return (
		<div
			className={cn(
				"absolute -bottom-[89px] left-1/2 -translate-x-1/2 translate-y-full truncate text-center opacity-0 group-hover:opacity-100",
				className,
			)}
		>
			{children}
		</div>
	);
};
export { BlurList, BlurListItem, BlurListItemInfo, BlurListItemOverlay };
